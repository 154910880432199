@font-face {
  font-family: "HelveticaLTPro-Bold";
  src: url(/public/HelveticaLTPro-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "HelveticaLTPro-Black";
  src: url(/public/HelveticaLTPro-Black.ttf) format("truetype");
}
@font-face {
  font-family: "HelveticaLTPro-Roman";
  src: url(/public/HelveticaLTPro-Roman.ttf) format("truetype");
}

h1{
  font-family: "HelveticaLTPro-Bold";
  font-size: 2rem;
}

h2{
  font-family: "HelveticaLTPro-Bold";
  font-size: 1.3rem;

  color: white;
}

h3{
  font-family: "HelveticaLTPro-Bold";
  font-size: 1.2rem;
}

h6{
  font-family: "HelveticaLTPro-Bold";
  font-size: 0.7rem;
  line-height: 1.2;
  color: white;
}

h4{
  font-family: "HelveticaLTPro-Bold";
  font-size: 1.1rem;
  color: white;
}

h5{
  font-family: "HelveticaLTPro-Bold";
  font-size: 0.85rem;
  line-height: 1.3;
  color: white;
}

p{
  font-family: "HelveticaLTPro-Roman";
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  line-height: 1.2;
  color: white;
}

button{
  font-family: "HelveticaLTPro-Bold";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.imgContainer-h img{
  height: 100%;
}

.imgContainer-h{
  width: fit-content;
}

.imgContainer img{
  width: 100%;
}
.imgContainer{
  height: fit-content;
}

.txt{
  text-align: center;
  border: none;
  width: 100%;
  padding: 0.6rem;
}

.fontstroke{
  -webkit-text-stroke: 1px white;
  color: transparent;
}


/* animation */
.animation {
  animation: fadeIn 1s;
  animation-iteration-count: infinite; /* Play animation in a loop */
  animation-direction: alternate; /* Alternate animation direction each cycle */
}


@keyframes fadeIn {
  0% {
    top: 83%;
  }
  100% {
    top: 87%;
  }
}

.slick-next{
  opacity: 0;
  pointer-events: none;
}

.slick-prev{
  opacity: 0;
  pointer-events: none;
}

.float {
  animation: float 3s infinite;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}