.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.qr-reader {
  width: 430px;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.qr-reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-reader .qr-box {
  width: 100% !important;
  left: 0 !important;
}

.qr-reader .qr-frame {
  position: absolute;
  fill: none;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

/* Media Queries for mobile screens */
@media (max-width: 426px) {
  .qr-reader {
    width: 100%;
  }
}

.ActBtn{
  background-color: #CD450A;
  text-transform: uppercase;
  font-weight: 550;
  border: none;
  outline: none;
  color: white;
  z-index: 0;
  position: relative;
  border-radius: 12px;
}

.ActBtn::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #CD450A;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.ActBtn::before {
  border-radius: 10px;
  content: "";
  background: linear-gradient(
    45deg,
    #FF7300, #FFFB00, #FF7300, #FFFB00
  );
  /* #FF0000, #FF7300, #FFFB00, #48FF00,
    #00FFD5, #002BFF, #FF00C8, #FF0000 */
  position: absolute;
  top:-2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(8px);
  animation: glowing 100s linear infinite;
  transition: opacity .3s ease-in-out;
  opacity: 1;
}

.ActCircle{
  background-color: #FFFB00;
  text-transform: uppercase;
  font-weight: 550;
  border: none;
  outline: none;
  color: white;
  z-index: 0;
  position: relative;
  border-radius: 50%;
}

.ActCircle::after {
  content: "";
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFFB00;
  left: 0;
  top: 0;
  border-radius: 50%;
}

.ActCircle::before {
  border-radius: 50%;
  content: "";
  background: linear-gradient(
    45deg,
    #FF7300, #FFFB00, #FF7300, #FFFB00
  );
  /* #FF0000, #FF7300, #FFFB00, #48FF00,
    #00FFD5, #002BFF, #FF00C8, #FF0000 */
  position: absolute;
  top:-2px;
  left: -2px;
  background-size: 600%;
  z-index: -1;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  filter: blur(5px);
  animation: glowing 20s linear infinite;
  transition: opacity .3s ease-in-out;
  opacity: 1;
}


@keyframes glowing {
  0% {background-position: 0 0;}
  50% {background-position: 400% 0;}
  100% {background-position: 0 0;}
}


.slick-dots li button:before {
  color: white !important;
}

.sliderContainer{
  height: fit-content;
}

#popupInstuction .slick-dots{
  transform: translateX("100%");
}